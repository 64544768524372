<template>
  <div class="md:p-4 max-w-xl">
    <sqr-page-header title="Campagnes" class="p-4 md:-0">
      <sqr-button
        icon="plus"
        label="Nouvelle Campagne"
        @click="add()"
        :loading="adding"
      />
    </sqr-page-header>
    <sqr-error-banner :error="loadError" />
    <sqr-error-banner :error="addError" />
    <sqr-progress v-show="loading" />
    <div class="bg-white shadow overflow-hidden sm:rounded-md">
      <ul>
        <li
          v-for="campaign in campaigns"
          :key="campaign.id"
          class="border-t first:border-t-0"
        >
          <router-link
            class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
            :to="{ name: 'campaign', params: { bid, cid: campaign.id } }"
          >
            <div class="px-4 py-4 sm:px-6">
              <div class="flex justify-between items-center">
                <span>
                  {{ campaign.name }}
                </span>
                <fa :icon="['far', 'chevron-right']" />
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import broker from '../broker';

import SqrButton from '@/sqrd-ui/SqrButton';
import SqrErrorBanner from '@/sqrd-ui/SqrErrorBanner';
import SqrProgress from '@/sqrd-ui/SqrProgress';

import stamp from '@/utils/stamp';

export default {
  name: 'Campaigns',
  mixins: [broker],
  components: { SqrButton, SqrErrorBanner, SqrProgress },
  computed: {
    ...mapState('campaigns', { campaigns: 'docs' }),
    ...mapState('campaigns', ['loading', 'loadError'])
  },
  mounted() {
    const bid = this.bid;
    this.sub({ path: `brokers/${bid}/campaigns` });
  },
  data() {
    return {
      adding: false,
      addError: null
    };
  },
  methods: {
    ...mapActions('campaigns', ['sub', 'unsub']),
    async add() {
      const name = prompt('Nom de la nouvelle campagne');
      if (!name) return;
      try {
        this.adding = true;
        this.addError = null;
        const bid = this.bid;
        const updated = stamp();
        await this.$db()
          .collection(`brokers/${bid}/campaigns`)
          .add({
            name,
            created: updated,
            updated,
            subject: '',
            text: '',
            recipients: {},

            // we need this to check on the link opening
            ctaLabel: '',
            ctaUrl: ''
            // cta message, documents, link
          });
      } catch (error) {
        this.addError = error;
      } finally {
        this.adding = false;
      }
    }
  },
  metaInfo: {
    title: 'Campagnes'
  }
};
</script>
